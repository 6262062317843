import React, { FC, SVGProps } from 'react';
import styled from 'styled-components';

import Travelers from '../../images/marketing/Companies/Travelers.svg';
import Tesla from '../../images/marketing/Companies/Tesla.svg';
import Apple from '../../images/marketing/Companies/Apple.svg';
import Disney from '../../images/marketing/Companies/Disney.svg';
import Amazon from '../../images/marketing/Companies/Amazon.svg';
import Nasa from '../../images/marketing/Companies/Nasa.svg';
import Pinterest from '../../images/marketing/Companies/Pinterest.svg';

const Layout = styled.section`
  display: flex;
  width: 100%;
  max-width: 1440px;
  z-index: 2;
  flex-direction: column;
  padding: 86px 36px 0 36px;
  margin: 0 auto;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 106px 66px 0 66px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 106px 66px 0 66px;
  }
`;

const Title = styled.p`
  text-align: center;
  margin-bottom: 32px;
  font-weight: 420;
  font-size: 20px;
  line-height: 26px;
  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 26px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 18px;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px and max-width: 1023px) {
    column-gap: 38px;
  }
  @media (min-width: 1024px) {
    column-gap: 58px;
  }
  @media (min-width: 1440px) {
    column-gap: 78px;
  }
`;

const IconWrap = styled.img`
  max-width: 74px;
  width: 100%;
  margin-bottom: 40px;
  @media (min-width: 768px) {
    max-width: 94px;
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  withSparkles?: boolean;
}

const SparkleSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='9'
      height='10'
      viewBox='0 0 9 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.69834 3.47615C5.68905 3.43696 5.68905 3.39615 5.69834 3.35697C4.88393 0 4.60584 0 4.24829 0C3.89074 0 3.51333 2.36794e-07 3.43388 3.31724H3.09619C-0.41968 3.91315 0.0173224 4.66797 0.0173224 4.72756C0.0173224 5.10497 0.0173222 5.28374 3.15579 5.60156H3.65238V5.91938C4.34761 9.01812 4.50652 9.3558 5.12229 9.29621C5.73807 9.23662 5.91684 8.89894 5.87712 5.66115C5.87712 5.66115 5.87712 5.52211 5.87712 5.44265C7.86349 5.16456 8.3998 5.20429 8.41967 4.4892C8.43953 3.7741 7.98267 3.85356 5.69834 3.47615Z'
        fill='#FEB81C'
      />
    </svg>
  );
};

const Companies: FC<Props> = ({
  text = 'Many companies already use illume to celebrate',
  withSparkles = false,
  ...props
}) => {
  return (
    <Layout {...props}>
      <Title>
        {text}
        {withSparkles && (
          <span style={{ position: 'relative' }}>
            <SparkleSvg style={{ position: 'absolute', top: 5, right: -15 }} />
            <SparkleSvg style={{ position: 'absolute', top: -3, right: -5 }} />
          </span>
        )}
      </Title>
      <IconContainer>
        <IconWrap alt='Travelers' src={Travelers} />
        <IconWrap alt='Tesla' src={Tesla} />
        <IconWrap alt='Apple' src={Apple} />
        <IconWrap alt='Disney' src={Disney} />
        <IconWrap alt='Amazon' src={Amazon} />
        <IconWrap alt='Nasa' src={Nasa} />
        <IconWrap alt='Pinterest' src={Pinterest} />
      </IconContainer>
    </Layout>
  );
};

export default Companies;
